import React from "react";
import logo from "./logotrans.png";

//https://t66cd9qsp1.execute-api.eu-west-1.amazonaws.com/VNAmaster?phone=555
class App extends React.Component {
  render() {
    return (
      <div className="App" style={{display:"flex", alignItems:"center" , width:"100%" , height:"100vh"}}>
        <img src={logo} className="App-logo" alt="logo" style={{alignSelf:"center", marginLeft: "auto",  marginRight:"auto"}}/>
      </div>
    );
  }
}

export default App;
